import ujs from '@rails/ujs'
ujs.start()

import './globals/jquery'
import './globals/moment'

import 'bootstrap-sass/assets/javascripts/bootstrap/transition'
import 'bootstrap-sass/assets/javascripts/bootstrap/alert'
import 'bootstrap-sass/assets/javascripts/bootstrap/collapse'
import 'bootstrap-sass/assets/javascripts/bootstrap/dropdown'
import 'bootstrap-sass/assets/javascripts/bootstrap/modal'
import 'bootstrap-sass/assets/javascripts/bootstrap/tooltip'
import 'bootswatch/spacelab/bootstrap.css'

import '#/application/js'
import '#/admin/entries/css'
import '#/admin/folders/js'
import '#/admin/front_page_slides/js'
import '#/admin/galleries/js'
import '#/admin/reports/js'
import '#/entries/css'
import '#/folders/css'
import '#/galleries/js'
import '#/links/css'
import '#/movies/css'
import '#/pages/js'
import '#/reports/js'
import '#/shared/js'
